<template>
    <div :class="{'d-inline-flex':!isMobile}" >
        <v-container>
        <m-dialog v-model="dialog" title="Customer">
            <customer-report v-model="customer"></customer-report>
        </m-dialog>

        <page-card title="Customer Search" icon="mdi-account-group">
             <v-card-text class="overflow-x-auto">
                <div>
                    <v-pagination
                    v-model="page"
                    :length="Math.ceil(data.foundRows/data.limit)"
                    :total-visible="7"
                    ></v-pagination>
                </div>
                <m-table v-if="!isMobile" :headers = "headers" :data = "data.data" v-on:rowClick="openCustomerDialog"></m-table>
                <v-list dense v-else>
                <v-template v-for="(data, packetIndex) in data.data" :key="packetIndex">
                    <v-hover>
                        <template  v-slot:default="{ hover }">
                        <v-list-item 
                            three-line
                            @click="openCustomerDialog(data)" 
                            :class="`elevation-${hover ? 5 : 0}`"
                            >
                            <v-list-item-content>
                                <v-list-item-title><strong>{{data.LAST_NAME}}, {{data.FIRST_NAME}}</strong></v-list-item-title>
                                <v-list-item-subtitle > DOB: {{formatDate(data.D_O_B)}} <br/> Phone: {{formatPhone(data.PHONE1)}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        </template>
                    </v-hover>
                    <v-divider></v-divider>
                </v-template>
            </v-list>
            </v-card-text>
        </page-card>
        </v-container>
    </div>
</template>

<script>
    import CustomerReport from '../components/CustomerReport.vue'
    import MTable from '../components/MTable.vue'
    import apiMixin from '../mixins/apiMixin'
    import appSearch from '../mixins/appSearchMixin'
    import overLoading from '../mixins/overLoading'
    import formatMixin from '../mixins/formatMixin'



      
    //   import CustomerDialog from '../components/CustomerDialog'




      export default {
          name: "Customers",
          components: {
              MTable,
              CustomerReport
          },
          mixins: [apiMixin, appSearch, overLoading, formatMixin],
          data() {
            return {
                dialog: false, 
                page: 1,
                headers: [
                    {label: "Last Name", key: "LAST_NAME", type: "text"},
                    {label: "First Name", key: "FIRST_NAME", type: "text"},
                    {label: "Middle Name", key: "MIDDLE", type: "text"},
                    {label: "DOB", key: "D_O_B", type: "date"},
                    {label: "Phone", key: "PHONE1", type: "phone"},
                    {label: "Address 1", key: "ADDRESS1", type: "text"},
                    {label: "Address 2", key: "ADDRESS2", type: "text"},
                    {label: "City", key: "CITY", type: "text"},
                    {label: "State", key: "STATE", type: "text"},
                ],
                data: [],
                customer: null
            }
          },
          watch: {
              dialog (val) {
                  this.customer = (val) ? this.customer :  null
              },
              page: function() {
                  this.getCustomers()
              },
              appSearch () {
                  this.page = 1
                  this.getCustomers()
              }
          },
          created() {
            this.getCustomers()
          },
          methods :{
            openCustomerDialog(customer) {
                this.dialog= true
                this.customer = customer
            },
            async getCustomers () {
                this.overLoading = true
                console.log("getting applicattions")
                let rowCount = 50
                let offset = (this.page - 1) * rowCount
                var payload =  {
                    search: {
                        expression :"CONCAT( IFNULL(LAST_NAME,''),', ',IFNULL(FIRST_NAME,''), ' ',IFNULL(D_O_B,''),IFNULL(PHONE1,''),IFNULL(ADDRESS1,''),IFNULL(ADDRESS2,''),IFNULL(CITY,''),IFNULL(SOC_NUM,''))",
                        pattern:  "%" + this.appSearch + "%"
                    },
                    table: "customer",
                    offset: offset,
                    rowCount: rowCount,
                    orderBy: " LAST_NAME, FIRST_NAME  ASC"
                }
                
                // this.data = await this.apiRead(payload)
                if (this.appSearch) {
                    this.apiRead(payload).then((response) => {
                        this.data = response
                        this.overLoading = false
                    })
                } else {
                    this.data = []
                    this.overLoading = false
                }
            },
          }

      }
</script>